#call-logs.MuiBox-root {
    margin: 16px;

    .call-logs-wr {
        height: calc(100vh - 134px);

        .cl-toolbar {
            box-sizing: content-box;
            padding: 16px 24px;
        }
    }
}

.call-details-wr.MuiDialog-root {
    .cd-appbar {
        padding: 12px 24px;
    }

    .cd-content {
        padding: 0;

        .cd-sidebar {
            max-height: 80vh;
            border-right: 1px solid #e0e0e0;

            .cd-sidebar-content {
                height: 100%;
                padding: 16px 24px;
            }
        }

        .cd-main {
            max-height: 80vh;
            overflow-y: hidden;

            .cd-main-content {
                height: 100%;

                .cd-player-wr {
                    padding: 12px 24px 12px;
                }

                .cd-transcript-wr {
                    margin-bottom: 12px;

                    .cd-transcript-content {
                        max-width: 100%;
                        max-height: calc(80vh - 112px);
                        overflow-y: auto;
                        padding: 8px 20px;
                        margin-bottom: 20px;

                        .cd-transcript-item {
                            padding: 6px 8px;
                            border-radius: 8px;
                            // margin: 4px 0;
                            cursor: pointer;
                        }
                    }
                }

                .cd-feedback-wr {
                    height: 100%;
                    margin-bottom: 12px;

                    .cd-feedback-content {
                        height: 100%;
                        padding: 0 24px;

                        .cd-feedback-iframe {
                            width: 100%;
                            height: 100%;
                            border: 1px solid #e0e0e0;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
    }
}