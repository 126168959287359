#sidenav.MuiStack-root {
    height: 100vh;
    padding: 16px 12px 24px 12px;  

    .sidenav-sec-1.MuiStack-root {
        width: 100%;

        .sidenav-logo-wr {
            text-align: center;

            .sidenav-logo {
                height: 76px;
                width: auto;
            }    
        }

        .sidenav-tabs-wr {
            margin: 24px 0;

            .sidenav-tab-link {
                text-decoration: none;

                &.active {
                    .sidenav-tab.MuiButton-root {
                        background-color: var(--ACCENT_COLOR);
                        color: var(--PRIMARY_COLOR);
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .sidenav-sec-2.MuiStack-root {
        width: 100%;

        .sidenav-user-info-wr.MuiStack-root {
            padding: 12px 20px;

            .user-avatar-icon {
                color: #484848;
            }
        }
    }
}